<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>用户列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">用户列表信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20" style="display: flex;align-items: center;">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索用户" @input="change"></el-input>
        </el-col>
        <el-col :span="2">
          <!-- <el-button type="primary" @click="tebleAdd">添加用户</el-button> -->
          <el-checkbox v-model="checked" @change="onChangeChecked">只显示待审核</el-checkbox>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520" @sort-change="onChangeSortable">
        <el-table-column prop="userName" label="用户名">
        </el-table-column>
        <el-table-column label="用户昵称">
          <template slot-scope="scope">
            <span v-if="scope.row.userMiniNick=='undefined'||scope.row.userName==''"></span><span v-else>{{scope.row.userMiniNick}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userPhone" label="用户手机">
        </el-table-column>
        <el-table-column label="认证类别">
          <template slot-scope="scope">
            <el-link type="success" v-if="scope.row.selfStatus == 1" :underline="false">农业技术&nbsp;</el-link>
            <el-link type="success" v-if="scope.row.teamStatus == 1" :underline="false">专业农服队&nbsp;</el-link>
            <el-link type="success" v-if="scope.row.expertStatus == 1" :underline="false">农业专家&nbsp;</el-link>
            <el-link type="success" v-if="scope.row.marketStatus == 1" :underline="false">农贸服务商&nbsp;</el-link>
            <el-link type="success" v-if="scope.row.assetStatus == 1" :underline="false">农资服务商&nbsp;</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间" sortable>
        </el-table-column>
        <el-table-column label="专家链接">
          <template slot-scope="scope">
            /pages/shareUserBox/shareUserBox?id={{ scope.row.userId }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-link type="warning" @click="review(scope.row.userId)"
              v-if="scope.row.selfStatus == 0 || scope.row.teamStatus == 0 || scope.row.expertStatus == 0 || scope.row.marketStatus == 0 || scope.row.assetStatus == 0"
              :underline="false">待审核</el-link>
            <el-button type="text" @click="review(scope.row.userId)" v-else>查看</el-button>
            <el-button type="text" @click="push(scope.row.userId)">推送</el-button>
            <el-button type="text" @click="toMsg(scope.row.userId)">消息</el-button>
            <!-- <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.userId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo"
        background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>



<script>
// import { filter } from 'vue/types/umd';
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
      checked: false,
      isPending:0,
      isAscByCreateTime:0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onChangeSortable(e){
      console.log(e);
      if(e.order=='descending'||e.order==null){
        this.isAscByCreateTime=0
        this.getList()
      }else{
        this.isAscByCreateTime=1
        this.getList()
      }
    },
    onChangeChecked(e){
      // console.log(e);
      // if(e==true){
      //   this.List=this.List.filter(item=>{
      //     return item.selfStatus == 0 || item.teamStatus == 0 || item.expertStatus == 0 || item.marketStatus == 0 || item.assetStatus == 0
      //   })
      //   // console.log(this.List);
      // }else{
      //   this.getList()
      // }
      if(e==true){
        this.isPending=1
        this.getList()
      }else{
        this.isPending=0
        this.getList()
      }
    },
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/user/pageWithStatus', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          userName: this.name,
          isPending:this.isPending,
          isAscByCreateTime:this.isAscByCreateTime
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/user/delete', { userId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/udetails',
        query: {
          id: id
        }
      });
    },
    //推送
    push(id) {
      this.$router.push({
        path: '/upush',
        query: {
          id: id
        }
      });
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/uadd',
      });
    },
    //聊天
    toMsg(id) {
      this.$router.push({
        path: '/uMsg',
        query: {
          id: id
        }
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
  }
}



</script>
<style scoped lang="scss">

</style>