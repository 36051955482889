<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/index' }">数据看板</el-breadcrumb-item>
            <el-breadcrumb-item>消息列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-card>
        <el-card class="box-card" shadow="never" style="margin-top:20px">
          <div class="textBox" v-for="(item, i) in audit" :key="i" @click="toDetail(item.type)">
            <span>{{ item.title }}</span>
            <span style="color:#888">{{ item.time }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      audit: []
    }
  },
  created() {
    this.massageList()
  },
  methods: {
    //获取待审核信息
    async massageList() {
      const { data: res } = await this.$http.get('/admin/home/massageList')
      this.audit = res.data
    },
    toDetail(type) {
      if (type == 'question') {
        this.$router.push({
          path: '/release_problem_list',
        });
      } else if (type == 'supply') {
        this.$router.push({
          path: '/release_supply_list',
        });
      } else if (type == 'need') {
        this.$router.push({
          path: '/release_demand_list',
        });
      } else if (type == 'task') {
        this.$router.push({
          path: '/release_task_list',
        });
      } else if (type == 'labor') {
        this.$router.push({
          path: '/release_service_list',
        });
      } else if (type == 'cash') {
        this.$router.push({
          path: '/withdrawal_list',
        });
      } else if (type == 'expert' || type == 'self' || type == 'team' || type == 'market') {
        this.$router.push({
          path: '/ulist',
        });
      }
    }
  },
}
</script>
<style scoped lang="less">
.textBox {
  display: flex;
  justify-content: space-between;

  &:hover {
    color: #409EFF;
    cursor: pointer;
  }

  border-bottom: 1px solid #f1f1f1;
  padding: 20px 0;

  &:last-child {
    border-bottom: none;
  }
}
</style>