<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_problem_list' }">问题列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_problem_details?id=' + id }">问题管理</el-breadcrumb-item>
            <el-breadcrumb-item>问题编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑问题信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="问题描述" :label-width="formLabelWidth" prop="questionContent">
              <el-input v-model="form.questionContent" autocomplete="off" placeholder="请输入问题描述"></el-input>
            </el-form-item>
            <el-form-item label="问题悬赏" :label-width="formLabelWidth" prop="questionFee">
              <el-input v-model="form.questionFee" type="number" autocomplete="off" placeholder="请输入问题悬赏"></el-input>
            </el-form-item>
            <el-form-item label="问题图片" :label-width="formLabelWidth" prop="userCompany">
              <div>
                <el-upload :action="action" list-type="picture-card" :on-success="success" :limit="3"
                  :file-list="fileList" :on-remove="remove">
                  <el-button size="small" type="primary">添加图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {},
      //图片
      fileList: [],
      // 表单信息验证项
      rules: {
        questionContent: [
          { required: true, message: '请输入问题内容', trigger: 'blur' },
        ],
        questionFee: [
          { required: true, message: '请输入问题悬赏', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.action = this.$http.defaults.baseURL + 'file/upLoadImage'
    this.getDetails()
  },
  methods: {
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/question/detail", {
        params: {
          questionId: this.id
        }
      })
      this.form = res.data
      if (res.data.pics != '') {
        let data = [];
        res.data.pics.map((value, index, arry) => {
          data.push({ url: value });
        });
        this.fileList = data
      } else {
        this.fileList = []
      }
    },
    //提交
    submitForm(formName) {
      let dataImg = []
      this.fileList.map((value, index, arry) => {
        dataImg.push(value.url);
      });
      this.form.pics = dataImg
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/question/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    //图片上传成功后
    success(response, file, fileList) {
      this.fileList.push({ url: response.data })
    },
    //移除图片
    remove(file, fileList) {
      this.fileList = []
      fileList.forEach(file => {
        this.fileList.push({ url: file.url });
      });
    },
  }
}
</script>
<style scoped lang="less">

</style>