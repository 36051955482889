<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>提现管理</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">提现列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-table :data="List" border height="520">
        <el-table-column prop="userName" label="提现人">
        </el-table-column>
        <el-table-column prop="fee" label="提现金额">
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-link :underline="false" type="warning" v-if="scope.row.status == 0">待审核</el-link>
            <el-link :underline="false" type="success" v-else-if="scope.row.status == 1">已通过</el-link>
            <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start" type="danger"
              v-else-if="scope.row.status == 2">
              <el-link :underline="false" type="danger">已拒绝</el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <el-button type="text" @click="review(scope.row.cashRecordId)">通过</el-button>
              <el-popconfirm title="确定要拒绝此条提现吗？" @confirm='deletes(scope.row.cashRecordId)'>
                <el-button type="text" slot="reference" style="margin-left:10px">拒绝</el-button>
              </el-popconfirm>
            </div>
            <div v-else>
              <el-link :underline="false" type="success" disabled v-if="scope.row.status == 1">已提现</el-link>
              <el-link :underline="false" type="danger" v-else>被驳回</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo"
        background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>
  
  
  
<script>
export default {
  data() {
    return {
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/cash/getPage')
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //通过审核
    review(id) {
      this.approveCash(id)
    },
    async approveCash(id) {
      const { data: res } = await this.$http.get('/admin/cash/approveCash?cashRecordId=' + id)
      this.$message.success(res.message);
      this.getList()
    },
    //驳回审核
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/cash/rejectCash', { cashRecordId: id })
      this.$message.success(res.message);
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



</script>
<style scoped lang="scss">

</style>