<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ulist' }">用户列表</el-breadcrumb-item>
            <el-breadcrumb-item>用户推送</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">推送用户信息</div>
        </el-card>
      </el-col>
    </el-row>
    <div class="centerBox warps">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="grid-content">
                    <el-form-item label="业务内容" :label-width="formLabelWidth" prop="thing1Value">
                      <el-row>
                        <el-col :span="24">
                          <el-input v-model="form.thing1Value" autocomplete="off" placeholder="业务内容"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="thing6Value">
                      <el-row>
                        <el-col :span="24">
                          <el-input v-model="form.thing6Value" autocomplete="off" placeholder="备注"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border height="600" style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column label="头像" width="120">
                <template slot-scope="scope">
                  <el-avatar :src="scope.row.userMiniHead" size="small"></el-avatar>
                </template>
              </el-table-column>
              <el-table-column label="姓名/昵称" width="120">
                <template slot-scope="scope">
                  <span>{{scope.row.userName||scope.row.userMiniNick}}</span>
                </template>
              </el-table-column>
              <el-table-column label="身份">
                <template slot-scope="scope">
                  <div v-if="scope.row.selfStatus==1">农业技术员</div>
                  <div v-if="scope.row.expertStatus==1">农业专家</div>
                  <div v-if="scope.row.teamStatus==1">专业农服队</div>
                  <div v-if="scope.row.marketStatus==1">农贸服务商</div>
                  <div v-if="scope.row.assetStatus==1">农资服务商</div>
                </template>
              </el-table-column>
              <el-table-column prop="userPhone" label="手机号" show-overflow-tooltip>
              </el-table-column>
            </el-table>
            <div style="text-align:right;padding:20px 0 0">
              <el-button type="primary" @click="submitForm('form')">推送</el-button>
              <el-button @click="resetForm('form')">重置</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      // 用户列表
      tableData: [],
      // 获取模板
      form: {
        type: 'user',
        id: '',
        thing1Value: '',
        thing6Value: '',
        userMiniWxs: '',
      },
      rules: {
        thing1Value: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        thing6Value: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      labelPosition: 'left'
    }
  },
  created() {
    this.form.id = this.$route.query.id
    // this.selectJobWantedById()
    this.selectLabelFlUser()
  },
  methods: {
    //获取求职详情
    async selectJobWantedById() {
      const { data: res } = await this.$http.get("/admin/user/detailWithStatus?userId=" + this.form.id)
      this.form.first = '尊敬的用户：您好！现在为您推送一位能人专家，请则需对接'
      this.form.keyword1 = '【相关职位】'
      this.form.keyword2 = res.data.userName
      this.form.keyword3 = '【工作经验】'
      this.form.keyword4 = '【最近工作】'
      this.form.keyword5 = '【教育经历】'
      this.form.remark = '点击链接了解详情，如有疑问请在对话框内咨询在线客服。'
      this.form.url = 'https://ylapp.nongliankeji.cn/pages/home/children/talent/talentDetail?id=' + this.form.id
    },
    // 获取人员信息
    async selectLabelFlUser() {
      const { data: res } = await this.$http.get("/admin/message/allUserWithStatus")
      this.tableData = res.data
    },
    //全选+点选
    toggleSelection(rows) {
      if (rows.teleNum) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      var info = [];
      val.map((value, index, arry) => {
        info.push(value.userMiniWx);
      });
      this.form.userMiniWxs = info
    },
    //提交模板信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pushMessage()
        }
      });
    },
    async pushMessage() {
      const { data: res } = await this.$http.post("/admin/message/sendMessage?type=" + this.form.type + '&id=' + this.form.id + '&thing1Value=' + this.form.thing1Value + '&thing6Value=' + this.form.thing6Value + '&userMiniWxs=' + this.form.userMiniWxs)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>
<style scoped lang="less">
.el-select,
.el-cascader,
.el-date-picker {
  width: 100% !important;
}
.warps {
  background: #f5f7fa;
  padding: 20px;
}
</style>