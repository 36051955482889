<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ulist' }">用户列表</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">用户管理</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户基本信息" name="1">
          <!-- 个人基本信息 -->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>个人基本信息</span>
              <el-button class="floatCaid" type="text" @click="changeUser(id)">编辑</el-button>
            </div>
            <div>
              <ul class="msgList">
                <li><span class="title_text">头像：</span>
                  <p>
                    <el-avatar :src="form.userMiniHead"></el-avatar>
                  </p>
                </li>
                <li><span class="title_text">昵称：</span>
                  <p>{{ form.userMiniNick }}</p>
                </li>
                <li><span class="title_text">姓名：</span>
                  <p>{{ form.userName }}</p>
                </li>
                <li><span class="title_text">手机号：</span>
                  <p>{{ form.userPhone }}</p>
                </li>
                <li><span class="title_text">身份证号：</span>
                  <p>{{ form.userIdcard }}</p>
                </li>
                <li>
                  <span class="title_text">用户收益：</span>
                  <p>{{ form.userProfit }}</p>
                </li>
                <li><span class="title_text">用户标签：</span>
                  <p>
                    <el-select v-model="userLabel" filterable multiple placeholder="请选择用户标签" @remove-tag="removeTag"
                      @change="changeNumid">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <el-button type="primary" style="margin-left:10px" @click="changeNumidUp">保存</el-button>
                  </p>
                </li>
                <li>
                  <span class="title_text">用户证书：</span>
                  <p>
                    <el-input v-model="userTag" placeholder="请输入证书"></el-input>
                    <el-button type="primary" style="margin-left:10px" @click="changeUserTag">保存</el-button>
                  </p>
                </li>
              </ul>
            </div>
          </el-card>

          <el-row :gutter="20" class="min_height_card">
            <!-- 个人认证 -->
            <el-col :span="6" v-if="checkSelfs">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>个人认证</span>
                  <el-link class="floatCaid" type="info" v-if="form.selfStatus == null">未认证</el-link>
                  <div class="floatCaid floatCaids" v-else-if="form.selfStatus == 0">
                    <el-button type="text" @click="approveSelfBtn">通过</el-button>
                    <el-button type="text" @click="open1">驳回</el-button>
                  </div>
                  <el-link class="floatCaid" type="success" v-else-if="form.selfStatus == 1">已通过</el-link>
                  <el-link class="floatCaid" type="danger" v-else-if="form.selfStatus == 2">已驳回</el-link>
                </div>
                <div>
                  <ul class="msgList">
                    <li>
                      <span class="title_text">个人擅长：</span>
                      <p>{{ checkSelfs.selfSkill }}</p>
                    </li>
                    <li>
                      <span class="title_text">技能证书：</span>
                      <p>
                        <span v-for="(item, i) in checkSelfsImg" :key="i">
                          <el-image :src="item" :preview-src-list="checkSelfsImg" class="uploadImg"></el-image>
                        </span>
                      </p>
                    </li>
                    <li v-if="checkSelfs.reason">
                      <span class="title_text">驳回原因：</span>
                      <p>
                        <el-link type="danger">{{ checkSelfs.reason }}</el-link>
                      </p>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>
            <!-- 服务队认证 -->
            <el-col :span="6" v-if="checkTeams">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>服务队认证</span>
                  <el-link class="floatCaid" type="info" v-if="form.teamStatus == null">未认证</el-link>
                  <div class="floatCaid floatCaids" v-else-if="form.teamStatus == 0">
                    <el-button type="text" @click="approveTeamBtn">通过</el-button>
                    <el-button type="text" @click="open2">驳回</el-button>
                  </div>
                  <el-link class="floatCaid" type="success" v-else-if="form.teamStatus == 1">已通过</el-link>
                  <el-link class="floatCaid" type="danger" v-else-if="form.teamStatus == 2">已驳回</el-link>
                </div>
                <div>
                  <ul class="msgList">
                    <li>
                      <span class="title_text">服务队名称：</span>
                      <p>{{ checkTeams.teamName }}</p>
                    </li>
                    <li>
                      <span class="title_text">服务队介绍：</span>
                      <p>{{ checkTeams.teamDesc }}</p>
                    </li>
                    <li>
                      <span class="title_text">服务队资质：</span>
                      <p>
                        <span v-for="(item, i) in checkTeamsImg" :key="i">
                          <el-image :src="item" :preview-src-list="checkTeamsImg" class="uploadImg"></el-image>
                        </span>
                      </p>
                    </li>
                    <li v-if="checkTeams.reason">
                      <span class="title_text">驳回原因：</span>
                      <p>
                        <el-link type="danger">{{ checkTeams.reason }}</el-link>
                      </p>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>
            <!-- 专家认证 -->
            <el-col :span="6" v-if="checkExperts">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>专家认证</span>
                  <el-link class="floatCaid" type="info" v-if="form.expertStatus == null">未认证</el-link>
                  <div class="floatCaid floatCaids" v-else-if="form.expertStatus == 0">
                    <el-button type="text" @click="approveExpertBtn">通过</el-button>
                    <el-button type="text" @click="open3">驳回</el-button>
                  </div>
                  <el-link class="floatCaid" type="success" v-else-if="form.expertStatus == 1">已通过</el-link>
                  <el-link class="floatCaid" type="danger" v-else-if="form.expertStatus == 2">已驳回</el-link>
                </div>
                <div>
                  <ul class="msgList">
                    <li>
                      <span class="title_text">专家单位：</span>
                      <p>{{ checkExperts.expertWork }}</p>
                    </li>
                    <li>
                      <span class="title_text">专家擅长：</span>
                      <p>{{ checkExperts.expertDesc }}</p>
                    </li>
                    <li>
                      <span class="title_text">专家证书：</span>
                      <p>
                        <span v-for="(item, i) in checkExpertsImg" :key="i">
                          <el-image :src="item" :preview-src-list="checkExpertsImg" class="uploadImg"></el-image>
                        </span>
                      </p>
                    </li>
                    <li v-if="checkExperts.reason">
                      <span class="title_text">驳回原因：</span>
                      <p>
                        <el-link type="danger">{{ checkExperts.reason }}</el-link>
                      </p>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>
            <!-- 农贸商认证 -->
            <el-col :span="6" v-if="checkMarkets">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>农贸商认证</span>
                  <el-link class="floatCaid" type="info" v-if="form.marketStatus == null">未认证</el-link>
                  <div class="floatCaid floatCaids" v-else-if="form.marketStatus == 0">
                    <el-button type="text" @click="approveMarketBtn">通过</el-button>
                    <el-button type="text" @click="open4">驳回</el-button>
                  </div>
                  <el-link class="floatCaid" type="success" v-else-if="form.marketStatus == 1">已通过</el-link>
                  <el-link class="floatCaid" type="danger" v-else-if="form.marketStatus == 2">已驳回</el-link>
                </div>
                <div>
                  <ul class="msgList">
                    <li>
                      <span class="title_text">企业名称：</span>
                      <p>{{ checkMarkets.marketName }}</p>
                    </li>
                    <li>
                      <span class="title_text">企业介绍：</span>
                      <p>{{ checkMarkets.marketDesc }}</p>
                    </li>
                    <li>
                      <span class="title_text">营业执照：</span>
                      <p v-if="checkMarketsImg">
                        <span v-for="(item, i) in checkMarketsImg" :key="i">
                          <el-image :src="item" :preview-src-list="checkMarketsImg" class="uploadImg"></el-image>
                        </span>
                      </p>
                      <p v-else>暂无图片</p>
                    </li>
                    <li v-if="checkMarkets.reason">
                      <span class="title_text">驳回原因：</span>
                      <p>
                        <el-link type="danger">{{ checkMarkets.reason }}</el-link>
                      </p>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="服务列表" name="4">
          <u-Service></u-Service>
        </el-tab-pane>
        <el-tab-pane label="任务列表" name="5">
          <u-Task></u-Task>
        </el-tab-pane>
        <el-tab-pane label="供应列表" name="6">
          <u-Supply></u-Supply>
        </el-tab-pane>
        <el-tab-pane label="求购列表" name="7">
          <u-Demand></u-Demand>
        </el-tab-pane>
        <el-tab-pane label="问题列表" name="8">
          <u-Problem></u-Problem>
        </el-tab-pane>
        <el-tab-pane label="服务队成员" name="9" v-if="form.teamStatus == 1">
          <u-MemberList></u-MemberList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>





<script>
import uService from './udetailsTab/uService.vue'
import uTask from './udetailsTab/uTask.vue'
import uSupply from './udetailsTab/uSupply.vue'
import uDemand from './udetailsTab/uDemand.vue'
import uProblem from './udetailsTab/uProblem.vue'
import uMemberList from './udetailsTab/uMemberList.vue'




export default {
  components: {
    uService,
    uTask,
    uSupply,
    uDemand,
    uProblem,
    uMemberList
  },
  data() {
    return {
      activeName: '1',
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      // 当前数据id
      id: '',
      //用户信息
      form: '',
      //用户标识/抬头
      userTag: '',
      //个人认证信息
      checkSelfs: '',
      checkSelfsImg: [],
      checkSelfsreason: '',
      //服务队认证信息
      checkTeams: '',
      checkTeamsImg: [],
      checkTeamsreason: '',
      //专家认证信息
      checkExperts: '',
      checkExpertsImg: [],
      checkExpertsreason: '',
      //农贸商认证信息
      checkMarkets: '',
      checkMarketsImg: [],
      checkMarketsreason: '',
      //农咨商认证信息
      checkAssets: '',
      checkAssetsImg: [],
      checkAssetsreason: '',
      // 打标签
      labelValue: 'user',
      options: [],
      userLabel: [],
      arrList: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    // 基本信息
    this.getDetails()
    //获取标签
    this.getByTypePage()
    //获取已经打的标签
    this.getUseLabelList()
    // 个人认证
    this.checkSelf()
    //服务商认证
    this.checkTeam()
    //专家认证
    this.checkExpert()
    //农贸商认证
    this.checkMarket()
    //农咨商认证
    // this.checkAsset()
  },
  methods: {
    //获取基本信息
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/user/detailWithStatus", {
        params: {
          userId: this.id
        }
      })
      this.form = res.data
      this.userTag = res.data.fileDesc
    },
    //点击跳转到修改信息页面
    changeUser(id) {
      this.$router.push({
        path: '/uchange',
        query: {
          id: id
        }
      });
    },
    //给专家打标识
    changeUserTag() {
      this.addFileDesc()
    },
    async addFileDesc() {
      const { data: res } = await this.$http.get('/admin/user/auth/addFileDesc?userId=' + this.id + '&authType=expert' + '&fileDesc=' + this.userTag)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //获取全部标签
    async getByTypePage() {
      const { data: res } = await this.$http.get('/admin/tag/getTagByLevel?level=4')
      var data = [];
      res.data.map((value, index, arry) => {
        data.push({
          value: value.id,
          label: value.name,
        });
      });
      this.options = data
    },
    // 查看当前已经打的标签
    async getUseLabelList() {
      const { data: res } = await this.$http.post('/admin/tagUse/getUseTagList?tagType=' + this.labelValue + '&useId=' + this.id)
      var data1 = [];
      res.data.map((value, index, arry) => {
        data1.push(value.tagId);
      });
      this.userLabel = data1
    },
    //打标签
    changeNumid(i) {
      this.arrList = i
    },
    changeNumidUp() {
      this.makeLabel()
    },
    async makeLabel() {
      const { data: res } = await this.$http.post("/admin/tagUse/makeTagBatch?tagType=" + this.labelValue + '&useId=' + this.id + '&tagIds=' + this.arrList)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //删除标签
    removeTag(i) {
      this.unMakeLabel(i)
    },
    async unMakeLabel(i) {
      const { data: res } = await this.$http.post("/admin/tagUse/unMakeTag?tagType=" + this.labelValue + '&useId=' + this.id + '&tagId=' + i)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //个人认证 --------------------------------------------------------------------------------------------------------------------------------------------------------
    //查看
    async checkSelf() {
      const { data: res } = await this.$http.get("/admin/user/auth/checkSelf", {
        params: {
          userId: this.id
        }
      })
      this.checkSelfs = res.data
      this.checkSelfsImg = res.data.pics
    },
    //通过
    approveSelfBtn() {
      this.approveSelf()
    },
    async approveSelf() {
      const { data: res } = await this.$http.get("/admin/user/auth/approveSelf", {
        params: {
          userId: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    //驳回
    open1() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkSelfsreason = value
        this.rejectSelf()
      })
    },
    async rejectSelf() {
      const { data: res } = await this.$http.post("/admin/user/auth/rejectSelf?userId=" + this.id + '&reason=' + this.checkSelfsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    // 服务队认证--------------------------------------------------------------------------------------------------------------------------------------------------------
    //查看
    async checkTeam() {
      const { data: res } = await this.$http.get("/admin/user/auth/checkTeam", {
        params: {
          userId: this.id
        }
      })
      this.checkTeams = res.data
      this.checkTeamsImg = res.data.pics
    },
    //通过
    approveTeamBtn() {
      this.approveTeam()
    },
    async approveTeam() {
      const { data: res } = await this.$http.get("/admin/user/auth/approveTeam", {
        params: {
          userId: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    //驳回
    open2() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkTeamsreason = value
        this.rejectTeam()
      })
    },
    async rejectTeam() {
      const { data: res } = await this.$http.post("/admin/user/auth/rejectTeam?userId=" + this.id + '&reason=' + this.checkTeamsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    // 专家认证--------------------------------------------------------------------------------------------------------------------------------------------------------
    //查看
    async checkExpert() {
      const { data: res } = await this.$http.get("/admin/user/auth/checkExpert", {
        params: {
          userId: this.id
        }
      })
      this.checkExperts = res.data
      this.checkExpertsImg = res.data.pics
    },
    //通过
    approveExpertBtn() {
      this.approveExpert()
    },
    async approveExpert() {
      const { data: res } = await this.$http.get("/admin/user/auth/approveExpert", {
        params: {
          userId: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    //驳回
    open3() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkExpertsreason = value
        this.rejectExpert()
      })
    },
    async rejectExpert() {
      const { data: res } = await this.$http.post("/admin/user/auth/rejectExpert?userId=" + this.id + '&reason=' + this.checkExpertsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    // 农贸商认证--------------------------------------------------------------------------------------------------------------------------------------------------------
    //查看
    async checkMarket() {
      const { data: res } = await this.$http.get("/admin/user/auth/checkMarket", {
        params: {
          userId: this.id
        }
      })
      this.checkMarkets = res.data
      this.checkMarketsImg = res.data.pics
    },
    //通过
    approveMarketBtn() {
      this.approveMarket()
    },
    async approveMarket() {
      const { data: res } = await this.$http.get("/admin/user/auth/approveMarket", {
        params: {
          userId: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    //驳回
    open4() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkMarketsreason = value
        this.rejectMarket()
      })
    },
    async rejectMarket() {
      const { data: res } = await this.$http.post("/admin/user/auth/rejectMarket?userId=" + this.id + '&reason=' + this.checkMarketsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    // 农咨商认证--------------------------------------------------------------------------------------------------------------------------------------------------------
    //查看
    async checkAsset() {
      const { data: res } = await this.$http.get("/admin/user/auth/checkAsset", {
        params: {
          userId: this.id
        }
      })
      this.checkAssets = res.data
      this.checkAssetsImg = res.data.pics
    },
    //通过
    approveAssetBtn() {
      this.approveAsset()
    },
    async approveAsset() {
      const { data: res } = await this.$http.get("/admin/user/auth/approveAsset", {
        params: {
          userId: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    },
    //驳回
    open5() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkAssetsreason = value
        this.rejectAsset()
      })
    },
    async rejectAsset() {
      const { data: res } = await this.$http.post("/admin/user/auth/rejectAsset?userId=" + this.id + '&reason=' + this.checkAssetsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getDetails()
    }
  }
}
</script>





<style scoped lang="less">
.msgList {
  font-size: 14px;
  color: #000;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 100px;
    margin-top: 20px;
    line-height: 20px;
  }

  .title_text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100px;
    text-align: right;
    font-weight: normal;
    color: #a4a4a4;
    padding-right: 10px;
  }

  p {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
}

.title_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}

.uploadImg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}

.min_height_card {
  .box-card {
    margin-top: 20px;
    min-height: 300px;
  }
}

.floatCaid {
  float: right;
  padding: 3px 0;
}

.floatCaids {
  margin-top: -12px;
}

.el-select {
  width: 500px !important;
}

.el-input {
  width: 500px !important;
}
</style>