<template>
  <div>
    <!-- <div class="wrap"> -->
    <el-table :data="List" border height="520">
      <el-table-column prop="userName" label="用户名"> </el-table-column>
      <el-table-column label="用户昵称">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.userMiniNick == 'undefined' || scope.row.userName == ''
            "
          ></span
          ><span v-else>{{ scope.row.userMiniNick }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userPhone" label="用户手机"> </el-table-column>
      <el-table-column prop="createTime" label="注册时间"> </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <!-- <el-link
            type="warning"
            @click="review(scope.row.userId)"
            v-if="
              scope.row.selfStatus == 0 ||
              scope.row.teamStatus == 0 ||
              scope.row.expertStatus == 0 ||
              scope.row.marketStatus == 0 ||
              scope.row.assetStatus == 0
            "
            :underline="false"
            >待审核</el-link
          > -->
          <el-button type="text" @click="review(scope.row.userId)"
            >查看</el-button
          >
          <!-- <el-button type="text" @click="push(scope.row.userId)">推送</el-button> -->
          <!-- <el-button type="text" @click="toMsg(scope.row.userId)">消息</el-button> -->
          <!-- <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.userId)'>
                <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
              </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- </div> -->
  </div>
</template>
  
  
  
  <script>
// import { filter } from 'vue/types/umd';
export default {
  data() {
    return {
      id: null,
      // 列表
      List: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    // 列表
    async getList() {
        const userId =Number(this.id)
        // const params={userId:id}
      const { data: res } = await this.$http.post(
        "/admin/teamMember/getTeamUserListByOwnerId?userId="+userId,
      );
      console.log(res);
      this.List = res.data;
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/udetails',
        query: {
          id: id
        }
      });
      location.reload();
    },
  },
};
</script>
  <style scoped lang="scss">
</style>