<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>开放平台</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">服务接口列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索开放用户" @input="change"></el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="labelValue" placeholder="标签类型" @change="changeLabel">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加接口</el-button>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="serviceName" label="接口名称">
        </el-table-column>
        <el-table-column prop="serviceUrl" label="接口路径">
        </el-table-column>
        <el-table-column prop="serviceCode" label="接口码">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-button type="danger" v-if="scope.row.enable==1" size="mini" @click="stateBtn(scope.row.serviceId)">禁用</el-button>
            <el-button type="success" v-else size="mini" @click="stateBtns(scope.row.serviceId)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>
    
    
    
    <script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 10,
      // 总条数
      total: 0,
      // 列表
      List: [],
      options: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '已启用'
      }],
      labelValue: '0'
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/open/openService/getServiceList', {
        params: {
          // pageNo: this.pageNo,
          //   pageSize: this.pageSize,
          //   loginLogName: this.name
        }
      })
      this.List = res.data
      // this.total = res.data.totalRows
    },
    // 已经启用列表
    async getListToo() {
      const { data: res } = await this.$http.get('/open/openService/getServiceEnable', {
        params: {
          // pageNo: this.pageNo,
          //   pageSize: this.pageSize,
          //   loginLogName: this.name
        }
      })
      this.List = res.data
      // this.total = res.data.totalRows
    },
    changeLabel(i) {
      if (i == 0) {
        this.getList()
      } else {
        this.getListToo()
      }
    },
    // 更改状态
    stateBtn(id) {
      this.toDisable(id)
    },
    async toDisable(id) {
      const { data: res } = await this.$http.post('/open/openService/toDisable?serviceId=' + id)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    stateBtns(id) {
      this.toEnable(id)
    },
    async toEnable(id) {
      const { data: res } = await this.$http.post('/open/openService/toEnable?serviceId=' + id)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/openServiceAdd',
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



    </script>
    <style scoped lang="scss"></style>