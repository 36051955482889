<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据看板</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row class="indexTop">
            <el-col :span="15" class="indexTopLeft" v-if="audit">
              <div class="hoverDiv" @click="toDetail(audit.type)">
                <i class="el-icon-bell" style="font-weight: bold;font-size: 18px;margin-right: 5px;position: relative;">
                  <b
                    style="position:absolute;width: 10px;height: 10px;border-radius: 50%;background: red;top: -5px;right: -10px;"></b>
                </i>
                {{ audit.title }}
              </div>
              <div>
                <el-link type="info" :underline="false">{{ audit.time }}</el-link>
                <el-link type="primary" style="margin-left:20px" @click="toMore">查看更多</el-link>
              </div>
            </el-col>
            <el-col :span="15" class="indexTopLeft" v-else>
              <div>
                暂无消息
              </div>
              <div></div>
            </el-col>
            <el-col :span="3" class="num">
              任务总数
              <div>{{ taskTotal }}</div>
            </el-col>
            <el-col :span="3" class="num">
              用户活跃度
              <div>{{ userActive }}</div>
            </el-col>
            <el-col :span="3" class="num">
              在线人数
              <div>{{ onlineNum }}</div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="cards" shadow="never">
      <el-tabs>
        <el-tab-pane label="人岗匹配">
          <el-row :gutter="20">
            <el-col :span="15">
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>人岗匹配</span>
                </div>
                <el-row class="indexTop">
                  <el-col :span="5" class="num" style="border-left:none">
                    新增人才
                    <div>{{ newUser }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    新增任务
                    <div>{{ newTask }}</div>
                  </el-col>
                  <el-col :span="4" class="num">
                    匹配数量
                    <div>{{ matchNum }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    成交数量
                    <div>{{ dealNum }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    匹配成功率
                    <div>{{ matchSuccessRate }}</div>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix" style="display:flex">
                  <span>人才搜索</span>
                </div>
                <div class="clickBtn">
                  <el-input v-model="form.userName" placeholder="请输入人才姓名" @input="change">
                    <template slot="prepend">人才搜索</template>
                  </el-input>
                </div>
                <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#f7f7f7' }"
                  height="862">
                  <el-table-column prop="userName" label="姓名">
                  </el-table-column>
                  <el-table-column prop="authType" label="人才类型">
                  </el-table-column>
                  <el-table-column prop="address" label="人才地区分布">
                  </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="form.pageNo" background :page-size="form.pageSize" layout="total, prev, pager, next"
                  :total="form.total" style="text-align: right;">
                </el-pagination>
              </el-card>
            </el-col>
            <el-col :span="9">
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>任务紧急度</span>
                </div>
                <el-table :data="tableDatas" border :header-cell-style="{ background: '#f7f7f7' }" height="289">
                  <el-table-column prop="order" label="排行" width="50">
                  </el-table-column>
                  <el-table-column prop="title" label="任务内容">
                  </el-table-column>
                  <el-table-column prop="area" label="任务地址" :show-overflow-tooltip="true">
                  </el-table-column>
                  <el-table-column prop="time" label="任务时间">
                  </el-table-column>
                </el-table>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>紧缺人才预警</span>
                </div>
                <el-table :data="tableDatass" border :header-cell-style="{ background: '#f7f7f7' }" height="289">
                  <el-table-column prop="order" label="排行">
                  </el-table-column>
                  <el-table-column prop="type" label="类型">
                  </el-table-column>
                  <el-table-column prop="area" label="地址">
                  </el-table-column>
                </el-table>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>匹配类别分布图</span>
                </div>
                <div ref="chart" style="width:100%;height: 350px;"></div>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="人才收益">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>人才收益</span>
                </div>
                <el-row class="indexTop">
                  <el-col :span="8" class="num" style="border-left:none">
                    平台总收益
                    <div>{{ totalProfit }}</div>
                  </el-col>
                  <el-col :span="8" class="num">
                    服务类总收益
                    <div>{{ laborProfit }}</div>
                  </el-col>
                  <el-col :span="8" class="num">
                    知识类总收益
                    <div>{{ knowledgeProfit }}</div>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix" style="display:flex">
                  <span>人才搜索</span>
                </div>
                <div class="clickBtn">
                  <el-input v-model="form.userName" placeholder="请输入人才姓名" @input="change">
                    <template slot="prepend">人才搜索</template>
                  </el-input>
                </div>
                <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#f7f7f7' }">
                  <el-table-column prop="userName" label="姓名">
                  </el-table-column>
                  <el-table-column prop="authType" label="人才类型">
                  </el-table-column>
                  <el-table-column prop="address" label="人才地区分布">
                  </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="form.pageNo" background :page-size="form.pageSize" layout="total, prev, pager, next"
                  :total="form.total" style="text-align: right;">
                </el-pagination>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="人才服务"><el-row :gutter="20">
            <el-col :span="15">
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>人才服务</span>
                </div>
                <el-row class="indexTop">
                  <el-col :span="5" class="num" style="border-left:none">
                    培训机构总数
                    <div>{{ orgNum }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    技能培训开课总数
                    <div>{{ trainingNum }}</div>
                  </el-col>
                  <el-col :span="4" class="num">
                    正在进行培训数
                    <div>{{ trainingOnlineNum }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    在线学习人数
                    <div>{{ trainingUserNum }}</div>
                  </el-col>
                  <el-col :span="5" class="num">
                    颁发证书数
                    <div>{{ passCheckNum }}</div>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>培训类别分布图</span>
                </div>
                <div id="myChart1" ref="charts" style="width:938px;height: 350px;"></div>
              </el-card>
            </el-col>
            <el-col :span="9">
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>培训人员来源</span>
                </div>
                <div ref="chartss" style="width:578px;height: 213px;"></div>
              </el-card>
              <el-card class="cards" shadow="never">
                <div slot="header" class="clearfix">
                  <span>师资类别分布</span>
                </div>
                <div ref="chartsss" style="width:578px;height: 213px;"></div>
              </el-card>
            </el-col>
          </el-row></el-tab-pane>
        <el-tab-pane label="人才画像">
          <el-card class="cards" shadow="never">
            <div slot="header" class="clearfix" style="display:flex">
              <span>人才画像</span>
            </div>
            <div class="clickBtn">
              <el-input v-model="form.userName" placeholder="请输入人才姓名" @input="change">
                <template slot="prepend">人才搜索</template>
              </el-input>
            </div>
            <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#f7f7f7' }">
              <el-table-column prop="userName" label="姓名">
              </el-table-column>
              <el-table-column prop="skill" label="专业技能">
              </el-table-column>
              <el-table-column prop="score" label="人才身价">
              </el-table-column>
              <el-table-column prop="authType" label="荣誉评定">
              </el-table-column>
              <el-table-column prop="profit" label="服务收益">
              </el-table-column>
              <el-table-column prop="authType" label="服务范围">
              </el-table-column>
              <el-table-column label="服务评价">
                <template slot-scope="scope">
                  <el-link type="success" :underline="false" v-for="(item, i) in scope.row.commons" :key="i">{{ item
                  }}、</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="responseTime" label="服务响应时间">
              </el-table-column>
              <el-table-column label="培训信息">
                <el-table-column label="培训名称" width="120">
                  <template slot-scope="scope">
                    <el-link :underline="false" v-for="(item1, i) in scope.row.trainingInfos" :key="i">{{
                        item1.orgName
                    }}、</el-link>
                  </template>
                </el-table-column>
                <el-table-column label="培训时间" width="120">
                  <template slot-scope="scope">
                    <el-link :underline="false" v-for="(item2, i) in scope.row.trainingInfos" :key="i">{{
                        item2.trainingTime
                    }}、</el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="培训机构" width="120">
                  <template slot-scope="scope">
                    <el-link :underline="false" v-for="(item3, i) in scope.row.trainingInfos" :key="i">{{
                        item3.trainingName
                    }}、</el-link>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="createTime" label="注册时间">
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="form.pageNo" background :page-size="form.pageSize" layout="total, prev, pager, next"
              :total="form.total" style="text-align: right;">
            </el-pagination>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      //单项数据合集
      taskTotal: '',
      userActive: '',
      onlineNum: '',
      newUser: '',
      newTask: '',
      matchNum: '',
      dealNum: '',
      matchSuccessRate: '',
      totalProfit: '',
      laborProfit: '',
      knowledgeProfit: '',
      orgNum: '',
      trainingNum: '',
      trainingOnlineNum: '',
      trainingUserNum: '',
      passCheckNum: '',
      // 审核消息
      audit: '',
      // 人岗匹配-人才搜索+页头
      form: {
        //搜索
        userName: '',
        pageNo: 1,
        pageSize: 50,
        total: 0,
      },
      // 人岗匹配-人才列表
      tableData: [],
      // 人岗匹配-任务紧急度
      tableDatas: [],
      // 人岗匹配-紧缺人才预警
      tableDatass: [],
      //统计图
      dataMax: [],
      values: '',
      values1: '',
      values2: '',
      // 培训类别分布
      dataMaxs: [],
      value: '',
      // 培训人员来源
      userArie: [],
      userNum: [],
      //教师类别分布图
      therArie: [],
      therNum: [],
    }
  },
  created() {
    this.massageList()
    this.getTotalItem()
    this.userList()
    this.getTaskUrgencyRate()
    this.getUrgencyTalentWarning()
    this.getClassDistribution()
    this.getTrainingTypeDistribution()
    this.getTrainingUserOrigin()
    this.getTeacherTypeDistribution()
  },
  mounted() {
    setTimeout(() => {
      this.drawLine()
    }, 1000);
  },
  methods: {
    //获取待审核信息
    async massageList() {
      const { data: res } = await this.$http.get('/admin/home/massageList')
      this.audit = res.data[0]
    },
    // 查看待审核消息
    toDetail(type) {
      // 保存链接的激活状态
      if (type == 'question') {
        this.$router.push({
          path: '/release_problem_list',
        });
        window.sessionStorage.setItem('activePath', activePath)
      } else if (type == 'supply') {
        this.$router.push({
          path: '/release_supply_list',
        });
      } else if (type == 'need') {
        this.$router.push({
          path: '/release_demand_list',
        });
      } else if (type == 'task') {
        this.$router.push({
          path: '/release_task_list',
        });
      } else if (type == 'labor') {
        this.$router.push({
          path: '/release_service_list',
        });
      } else if (type == 'cash') {
        this.$router.push({
          path: '/withdrawal_list',
        });
      } else if (type == 'expert' || type == 'self' || type == 'team' || type == 'market') {
        this.$router.push({
          path: '/ulist',
        });
      }
    },
    //获取所有单项数据
    async getTotalItem() {
      const { data: res } = await this.$http.get('/admin/home/getTotalItem')
      this.taskTotal = res.data.taskTotal
      this.userActive = res.data.userActive
      this.onlineNum = res.data.onlineNum
      this.newUser = res.data.newUser
      this.newTask = res.data.newTask
      this.matchNum = res.data.matchNum
      this.dealNum = res.data.dealNum
      this.matchSuccessRate = res.data.matchSuccessRate
      this.totalProfit = res.data.totalProfit
      this.laborProfit = res.data.laborProfit
      this.knowledgeProfit = res.data.knowledgeProfit
      this.orgNum = res.data.orgNum
      this.trainingNum = res.data.trainingNum
      this.trainingOnlineNum = res.data.trainingOnlineNum
      this.trainingUserNum = res.data.trainingUserNum
      this.passCheckNum = res.data.passCheckNum
    },
    //查看更多审核消息
    toMore() {
      this.$router.push({
        path: '/indexList',
      });
    },
    //获取任务紧急度
    async getTaskUrgencyRate() {
      const { data: res } = await this.$http.get('/admin/home/match/getTaskUrgencyRate')
      this.tableDatas = res.data
    },
    //紧缺人才预警
    async getUrgencyTalentWarning() {
      const { data: res } = await this.$http.get('/admin/home/match/getUrgencyTalentWarning')
      this.tableDatass = res.data
    },
    //匹配类别分布图
    async getClassDistribution() {
      const { data: res } = await this.$http.get('/admin/home/match/getClassDistribution')
      // 专家值
      let infoItem = res.data.专家
      this.values = Object.values(infoItem);
      // 个人值
      let infoItem1 = res.data.服务队
      this.values1 = Object.values(infoItem1);
      // 服务队值
      let infoItem2 = res.data.个人
      this.values2 = Object.values(infoItem2);
      //把key输出
      let maxArr = this.values.concat(this.values1).concat(this.values2)
      let msxNum = Math.max.apply(null, maxArr)
      var keys = Object.keys(infoItem);
      var data = [];
      keys.map((value, index, arry) => {
        data.push({
          name: value,
          max: msxNum,
        });
      });
      this.dataMax = data
    },
    //培训类别分布图
    async getTrainingTypeDistribution() {
      const { data: res } = await this.$http.get('/admin/home/service/getTrainingTypeDistribution')
      let infoItem = res.data.社会化服务
      this.value = Object.values(infoItem);
      let maxArr = this.value
      let msxNum = Math.max.apply(null, maxArr)
      var keys = Object.keys(infoItem);
      var data = [];
      keys.map((value, index, arry) => {
        data.push({
          name: value,
          max: msxNum,
        });
      });
      this.dataMaxs = data
    },
    // //培训人员来源图
    async getTrainingUserOrigin() {
      const { data: res } = await this.$http.get('/admin/home/service/getTrainingUserOrigin')
      var data = [];
      var data1 = [];
      res.data.map((value, index, arry) => {
        data.push(value.address);
        data1.push(value.sum);
      });
      this.userArie = data
      this.userNum = data1
    },
    // //师咨类别分布
    async getTeacherTypeDistribution() {
      const { data: res } = await this.$http.get('/admin/home/service/getTeacherTypeDistribution')
      var data = [];
      var data1 = [];
      res.data.map((value, index, arry) => {
        data.push(value.type);
        data1.push(value.sum);
      });
      this.therArie = data
      this.therNum = data1
    },
    //获取通用人才列表
    async userList() {
      const { data: res } = await this.$http.get('/admin/home/userList', {
        params: {
          pageNo: this.form.pageNo,
          pageSize: this.form.pageSize,
          userName: this.form.userName,
        }
      })
      this.tableData = res.data.rows
      this.form.total = res.data.totalRows
    },
    // 通用-搜索人才
    change(e) {
      this.form.userName = e
      this.userList()
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.userList()
    },
    handleCurrentChange(val) {
      this.form.pageNo = val
      this.userList()
    },
    drawLine() {
      //匹配类别分布图
      let option = {
        radar: {
          center: ['50%', '45%'],
          radius: 130,
          indicator: this.dataMax,
          shape: 'circle',
        },
        tooltip: { confine: true },
        legend: {
          show: true,
          icon: 'rect',
          top: '95%',
          left: '30%',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 30,
          orient: 'horizontal',
          textStyle: {
            fontSize: 15,
            color: '#000'
          },
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                name: '个人',
                value: this.values1,
                itemStyle: {
                  color: '#409EFF',
                },
              },
              {
                name: '服务队',
                value: this.values2,
                itemStyle: {
                  color: '#FACC16',
                },
              },
              {
                name: '专家',
                value: this.values,
                itemStyle: {
                  color: '#2DC25B',
                },
              },
            ],
          },
        ],
      }
      this.myChart = echarts.init(this.$refs.chart);
      this.myChart.setOption(option);
      //培训类别分布图
      let option1 = {
        radar: {
          center: ['50%', '45%'],
          radius: 130,
          indicator: this.dataMaxs,
          shape: 'circle',
        },
        tooltip: { confine: true },
        legend: {
          show: true,
          icon: 'rect',
          top: '95%',
          left: '45%',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 30,
          orient: 'horizontal',
          textStyle: {
            fontSize: 15,
            color: '#000'
          },
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                name: '社会化服务',
                value: this.value,
                itemStyle: {
                  color: '#409EFF',
                },
              }
            ],
          },
        ],
      }
      this.myChart = echarts.init(this.$refs.charts);
      this.myChart.setOption(option1);
      //培训人员来源
      let options = {
        grid: [
          {
            left: '3%',
            top: '5%',
            right: '15%',
            bottom: '5%',
            containLabel: true
          }
        ],
        xAxis: {},
        yAxis: {
          data: this.userArie
        },
        series: [
          {
            type: 'bar',
            data: this.userNum,
            barWidth: 20,
            color: '#409EFF'
          }
        ]
      }
      this.myChart = echarts.init(this.$refs.chartss);
      this.myChart.setOption(options);
      //师资类别分布
      let optionss = {
        grid: [
          {
            left: '3%',
            top: '5%',
            right: '15%',
            bottom: '5%',
            containLabel: true
          }
        ],
        tooltip: {},
        xAxis: {
          data: this.therArie
        },
        yAxis: {},
        series: [
          {
            type: 'scatter',//type: scatter表示散点图
            data: this.therNum,
            color: '#409EFF'
          }
        ]
      }
      this.myChart = echarts.init(this.$refs.chartsss);
      this.myChart.setOption(optionss);
    },
  },
}
</script>
<style scoped lang="less">
.indexTop {
  margin-top: 20px;
  height: 56px;

  .indexTopLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px 0 0;

    .hoverDiv {
      &:hover {
        color: #409EFF;
        cursor: pointer;
      }
    }
  }

  .num {
    text-align: center;
    font-size: 15px;
    color: #888;
    border-left: 1px solid #F1F1F1;

    &>div {
      color: #000;
      font-weight: bold;
      font-size: 25px;
      margin-top: 10px;
    }
  }
}

.cards {
  margin: 20px 0;
}

.clickBtn {
  margin-bottom: 20px;
}
</style>