<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>开放平台</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">用户列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索开放用户" @input="change"></el-input>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="userName" label="姓名">
        </el-table-column>
        <el-table-column prop="userPhone" label="手机号">
        </el-table-column>
        <el-table-column prop="userEmail" label="邮箱">
        </el-table-column>
        <el-table-column prop="userDesc" label="描述">
        </el-table-column>
        <el-table-column prop="certificate" label="接口凭证">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-link type="success" v-if="scope.row.enable == 1" size="mini">已启用</el-link>
            <el-link type="danger" v-else size="mini">已禁用</el-link>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" v-if="scope.row.enable == 1" size="mini"
              @click="stateBtn(scope.row.userId)">禁用</el-button>
            <el-button type="success" v-else size="mini" @click="stateBtns(scope.row.userId)">启用</el-button>
            <el-link type="primary" style="margin-left:10px" @click="authorizationCode(scope.row.userId)">接口授权</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo"
        background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
    <!-- 授权弹窗 -->
    <el-dialog title="接口授权" :visible.sync="dialogFormVisible">
      <el-select v-model="userLabel" filterable multiple placeholder="请选择用户标签" @remove-tag="removeTag"
        @change="changeNumid">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeNumidUp">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  
  
<script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 10,
      // 总条数
      total: 0,
      // 列表
      List: [],
      //授权弹窗
      dialogFormVisible: false,
      options: [],
      userLabel: [],
      userIds: '',
      arrList: []
    }
  },
  created() {
    this.getList()
    this.getServiceList()

  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/open/openUser/getOpenUserList', {
        params: {
          // pageNo: this.pageNo,
          //   pageSize: this.pageSize,
          //   loginLogName: this.name
        }
      })
      this.List = res.data
      // this.total = res.data.totalRows
    },
    // 更改状态
    stateBtn(id) {
      this.toDisable(id)
    },
    async toDisable(id) {
      const { data: res } = await this.$http.post('/open/openUser/toDisable?userId=' + id)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    stateBtns(id) {
      this.toEnable(id)
    },
    async toEnable(id) {
      const { data: res } = await this.$http.post('/open/openUser/toEnable?userId=' + id)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //接口授权
    authorizationCode(id) {
      this.dialogFormVisible = true
      this.getServiceEnableListByUserId(id)
    },
    //获取该用户拥有的接口
    async getServiceEnableListByUserId(id) {
      this.userIds = id
      const { data: res } = await this.$http.get('/open/openUser/getServiceEnableListByUserId?userId=' + id)
      var data1 = [];
      res.data.map((value, index, arry) => {
        data1.push(value.serviceId);
      });
      if (data1 != null) {
        this.userLabel = data1
      } else {
        this.userLabel = []
      }
    },
    //根据id获取全部接口
    async getServiceList() {
      const { data: res } = await this.$http.get('/open/openService/getServiceList')
      var data = [];
      res.data.map((value, index, arry) => {
        data.push({
          value: value.serviceId,
          label: value.serviceName,
        });
      });
      this.options = data
    },
    //解除关系
    removeTag(i) {
      this.toDel(i)
    },
    async toDel(i) {
      const { data: res } = await this.$http.post("/open/openUserService/toDel?userId=" + this.userIds + '&serviceId=' + i)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //打标签
    changeNumid(i) {
      this.arrList = i
    },
    changeNumidUp() {
      this.makeLabel()
    },
    async makeLabel() {
      const { data: res } = await this.$http.post("/open/openUserService/toAddBatch?userId=" + this.userIds + '&serviceId=' + this.arrList)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.dialogFormVisible = false
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
  }
}



</script>
<style scoped lang="scss">

</style>