<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>新标签列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">新标签列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <!-- <el-row class="operation" :gutter="20">
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加标签</el-button>
        </el-col>
      </el-row> -->
      <el-table :data="List" border height="520">
        <el-table-column prop="name" label="标签名称(三级)">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="review(scope.row.id)">查看</el-button> -->
            <el-button type="text" @click="closeTagTherr(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-dialog title="添加标签" :visible.sync="dialogFormVisible">
        <el-input v-model="form.name"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="endBtn">确 定</el-button>
        </div>
      </el-dialog> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        fatherId: '',
        level: 3,
      },
      dialogFormVisible: false,
      // 列表
      List: [],
    }
  },
  created() {
    this.form.fatherId = this.$route.query.id
    this.getList()
  },
  methods: {
    // 添加标签
    tebleAdd() {
      this.dialogFormVisible = true
    },
    endBtn() {
      if (this.form.name) {
        this.addTag()
      }
    },
    async addTag() {
      const { data: res } = await this.$http.post('/admin/tag/addTag?level=' + this.form.level + '&name=' + this.form.name + '&fatherId=' + this.form.fatherId)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.dialogFormVisible = false
      this.form.name == ''
      this.getList()
    },
    // 获取某些类型列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/tag/getNextLevelById?id=' + this.form.fatherId)
      this.List = res.data
    },
    //点击查看
    review(id) {
      this.$router.push({
        path: '/newLabel_editor4',
        query: {
          id: id
        }
      });
    },
    //删除最后一级标签
    closeTagTherr(i) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delTagByIdCascade(i)
      })
    },
    async delTagByIdCascade(i) {
      const { data: res } = await this.$http.get('/admin/tag/delTagByIdCascade?id=' + i)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
  }
}
</script>
<style scoped>
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
</style>