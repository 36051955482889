<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ulist' }">用户列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">消息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div style="padding-top:50px">
      <JwChat-index :taleList="taleList" v-model="inputMsg" @enter="bindEnter" :config="config"
        :winBarConfig="winBarConfig" scrollType="scroll" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      myMsg: {
        id: '200231',
        img: 'https://admin.nongliankeji.cn/Public/admin/images/basicprofile.png',
        name: '点点帮农官方账号',
      },
      user: {
        id: '',
        img: '',
        name: '',
      },
      inputMsg: '',
      taleList: [],
      tool: {
        // show: ['file', 'img'],
        callback: this.toolEvent
      },
      config: {
        img: '',
        name: '',
        // dept: '',
        callback: this.bindCover,
        historyConfig: {
          show: true,
          tip: '加载更多',
          callback: this.bindLoadHistory,
        },
      },
      winBarConfig: {
        active: '',
        width: '300px',
        listHeight: '60px',
        list: [],
        callback: this.bindWinBar,
      }
      // date: null
    }
  },
  created() {
    this.id = this.$route.query.id
    // this.getDetails()
    setTimeout(() => {
      this.connect()
      this.sessionList()
      this.history()
      this.liveMsg()
    }, 800)
  },
  beforeDestroy() {
    this.disconnect()
  },
  methods: {
    //建立连接
    connect() {
      this.goEasy.connect({
        id: this.myMsg.id,
        data: { avatar: this.myMsg.img, nickname: this.myMsg.name },
      });
    },
    // 获取对方的信息
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/user/detailWithStatus?userId=" + this.user.id)
      this.user.id = res.data.userId
      this.user.img = res.data.userMiniHead
      this.user.name = res.data.userName
      this.config.img = res.data.userMiniHead
      this.config.name = res.data.userName
    },
    //获取会话列表
    sessionList() {
      var im = this.goEasy.im;
      im.latestConversations({
        onSuccess: result => {
          let data = [];
          result.content.conversations.map((value, index, arry) => {
            data.push({
              id: value.userId,
              img: value.data.avatar,
              name: value.data.nickname,
              dept: value.lastMessage.payload.text,
              readNum: value.unread
            });
          });
          this.winBarConfig.list = data
          // this.winBarConfig.active = data[0].id
        }
      });
    },
    // 获取历史记录
    history() {
      var im = this.goEasy.im;
      im.history({
        userId: this.user.id, //对方id
        lastTimestamp: null, //时间戳，第一次为null，后面每次为最后一次数据的id
        limit: 10, //每次获取的条数
        onSuccess: result => {
          let res = result.content
          let data = [];
          res.map((value, index, arry) => {
            data.push({
              date: value.timestamp,
              text: value.payload,
              mine: value.senderId == this.myMsg.id ? true : false,
              name: value.senderId == this.myMsg.id ? this.myMsg.name : this.user.name,
              img: value.senderId == this.myMsg.id ? this.myMsg.img : this.user.img
            });
          });
          this.taleList = data.concat(this.taleList)
          // this.date = this.taleList[0].date
        }
      });
    },
    bindLoadHistory() {
      this.history()
      // console.log('加载历史')
    },
    // 获取私聊消息
    liveMsg() {
      var im = this.goEasy.im;
      var onPrivateMessageReceived = message => {
        let form = {
          date: message.timestamp,
          text: message.payload,
          mine: message.senderId == this.myMsg.id ? true : false,
          name: message.senderId == this.myMsg.id ? this.myMsg.name : this.user.name,
          img: message.senderId == this.myMsg.id ? this.myMsg.img : this.user.img
        }
        this.taleList.push(form)
      };
      im.on(this.GoEasy.IM_EVENT.PRIVATE_MESSAGE_RECEIVED, onPrivateMessageReceived);
    },
    // 输入框点击就发送或者回车触发的事件
    bindEnter(e) {
      if (e) {
        var im = this.goEasy.im;
        let textMessage = im.createTextMessage({
          text: this.inputMsg, //消息内容
          to: {
            type: this.GoEasy.IM_SCENE.PRIVATE,
            id: this.user.id,
            data: { avatar: this.user.img, nickname: this.user.name }
          }
        });
        //发送
        im.sendMessage({
          message: textMessage,
          onSuccess: message => {
            console.log("发送成功");
            let form = {
              date: message.timestamp,
              text: message.payload,
              mine: message.senderId == this.myMsg.id ? true : false,
              name: message.senderId == this.myMsg.id ? this.myMsg.name : this.user.name,
              img: message.senderId == this.myMsg.id ? this.myMsg.img : this.user.img
            }
            this.taleList.push(form)
            this.sessionList()
          }
        });
      }
    },
    //断开链接
    disconnect() {
      this.goEasy.disconnect({
        onSuccess: function () {
          console.log("断开成功")
        },
      });
    },
    bindCover(type) {
      console.log('header', type)
    },
    bindWinBar(play = {}) {
      const { type, data = {} } = play
      this.user.id = data.id
      this.taleList = []
      this.getDetails()
      this.history()
    }
  }
}



</script>
<style scoped lang="scss">

</style>