import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

// 导入axios包
import axios from 'axios'
// axios.defaults.baseURL = 'http://alspd.natapp1.cc/' //根路径
axios.defaults.baseURL = 'https://test.nongliankeji.cn/' //根路径
// axios.defaults.baseURL = 'http://192.168.40.25:8080/' //根路径



// axios.interceptors.request.use(config => {
//     let token = window.sessionStorage.getItem("token")
//     if (token) {
//         config.headers.token = token;
//     }
//     return config;
// })
// axios.interceptors.response.use(res => {
//     console.log(res.headers['set-cookie']);
//     return res

// }, error => {
//     if (error.response.status === 401) {
//         // 401 说明 token 验证失败
//         // 可以直接跳转到登录页面，重新登录获取 token
//         location.href = '/login'
//     } else if (error.response.status === 500) {
//         // 服务器错误
//         // do something
//         return Promise.reject(error.response.data)
//     }
//     // 返回 response 里的错误信息
//     return Promise.reject(error.response.data)
// })

Vue.prototype.$http = axios
Vue.prototype.$store = store
    // 重置样式表
import './assets/css/normalize.css'
import './assets/css/base.css'
import './assets/css/style.css'

Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')