<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/permission_list' }">权限列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加权限</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">添加权限信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="权限编码" :label-width="formLabelWidth" prop="permissionCode">
              <el-input v-model="form.permissionCode" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="权限名称" :label-width="formLabelWidth" prop="permissionName">
              <el-input v-model="form.permissionName" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="权限访问路径" :label-width="formLabelWidth" prop="url">
              <el-input v-model="form.url" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      // 表单信息
      form: {
        permissionCode: '',
        permissionName: '',
        url: '',
        deleted: '',
      },
      // 表单信息验证项
      rules: {
        // varietyName: [
        //   { required: true, message: '请输入名称', trigger: 'blur' },
        // ],
        // varietyNumber: [
        //   { required: true, message: "请输入编号", trigger: "blur" },
        // ],
        // varietySource: [
        //   { required: true, message: '请输入来源', trigger: 'change' }
        // ],
        // varietyFirm: [
        //   { required: true, message: '请输入育种单位', trigger: 'change' }
        // ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left'
    }
  },
  methods: {
    //添加
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addForm()
        }
      });
    },
    async addForm() {
      const { data: res } = await this.$http.post("/admin/sysPermission/add", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    }
  }
}
</script>



<style scoped lang="less">

</style>