<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/teaching_list' }">教学列表</el-breadcrumb-item>
            <el-breadcrumb-item>教学详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">教学详情</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <el-row :gutter="20" class="min_height_card">
        <!-- 个人认证 -->
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>教学详情</span>
              <el-button class="floatCaid" type="text" @click="toEditor(id)">编辑</el-button>
            </div>
            <div>
              <ul class="msgList">
                <li>
                  <span class="title_text">教学名称：</span>
                  <p>{{checkSelfs.teachName}}</p>
                </li>
                <!-- <li>
                  <span class="title_text">教学类型：</span>
                  <p>{{checkSelfs.teachType}}</p>
                </li> -->
                <li>
                  <span class="title_text">封面图：</span>
                  <p v-if="checkSelfsImg!=''">
                    <span v-for="(item,i) in checkSelfsImg" :key="i">
                      <el-image :src="item" :preview-src-list="checkSelfsImg" class="uploadImg"></el-image>
                    </span>
                  </p>
                  <p v-else>暂无图片</p>
                </li>
                <li>
                  <span class="title_text">教学简介：</span>
                  <p v-html="checkSelfs.teachDesc"></p>
                </li>
                <li>
                  <span class="title_text">教学章节：</span>
                  <el-link target="_blank" @click="dialogVisible = true" type="primary" :underline="false" style="margin-bottom:20px">添加新章节</el-link>
                  <p>
                    <el-table :data="chapter" border>
                      <el-table-column prop="chapterName" label="章节标题">
                      </el-table-column>
                      <el-table-column prop="chapterUrl" label="章节连接">
                      </el-table-column>
                      <el-table-column prop="createTime" label="录入时间">
                      </el-table-column>
                      <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                          <el-button type="text" @click="dialogVisibleEditor = true;afut(scope.row)">编辑</el-button>
                          <el-popconfirm title="确定删除此条数据吗？" @confirm='removes(scope.row.chapterId)'>
                            <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </p>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520" style="margin-top:20px">
        <el-table-column prop="userId" label="评论人">
        </el-table-column>
        <el-table-column prop="teachCommentContent" label="评论内容">
        </el-table-column>
        <el-table-column prop="createTime" label="评论时间">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.teachCommentId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 编辑章节 -->
      <el-dialog title="编辑章节" :visible.sync="dialogVisibleEditor" width="50%">
        <div style="margin-top: 15px;">
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入章节名称" v-model="editorMsg.chapterName" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input placeholder="请输入章节地址" v-model="editorMsg.chapterUrl" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="4">
              <el-input placeholder="请输入时长" v-model="editorMsg.chapterDuration" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" @click="editorTag" plain>确认修改</el-button>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEditor = false">关闭</el-button>
        </span>
      </el-dialog>
      <!-- 添加章节 -->
      <el-dialog title="添加的章节" :visible.sync="dialogVisible" width="50%">
        <div style="margin-top: 15px;">
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入章节名称" v-model="msg.chapterName" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input placeholder="请输入章节地址" v-model="msg.chapterUrl" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="4">
              <el-input placeholder="请输入时长" v-model="msg.chapterDuration" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" @click="addTag" plain>确认添加</el-button>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>





<script>
export default {
  data() {
    return {
      id: '',
      checkSelfs: '',
      checkSelfsImg: [],
      // 评论列表
      List: [],
      // 问题标签
      dialogVisible: false,
      dialogVisibleEditor: false,
      //章节
      chapter: [],
      editorMsg: {
        chapterName: '',
        chapterUrl: '',
        chapterId: '',
        chapterDuration: ''
      },
      msg: {
        chapterName: '',
        chapterUrl: '',
        chapterDuration: '',
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.msg.teachId = this.$route.query.id
    this.checkSelf()
    this.getPageByTeachId()
    this.getAllByQuestionIdPage()
  },
  methods: {
    //点击跳转到修改信息页面
    toEditor(id) {
      this.$router.push({
        path: '/teaching_editor',
        query: {
          id: id
        }
      });
    },
    //查看
    async checkSelf() {
      const { data: res } = await this.$http.get("/admin/teach/detail", {
        params: {
          teachId: this.id
        }
      })
      this.checkSelfs = res.data
      this.checkSelfsImg = res.data.teachHead.split()
    },
    //评论列表
    async getAllByQuestionIdPage() {
      const { data: res } = await this.$http.get('/admin/teach/common/getPageByTeachId', {
        params: {
          teachId: this.id,
        }
      })
      this.List = res.data.rows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/teach/common/delete', { teachCommentId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getAllByQuestionIdPage()
    },
    // --------------------------------------------------------------------------------------------------------------
    // 获取章节
    async getPageByTeachId() {
      const { data: res } = await this.$http.get("/admin/teach/chapter/getPageByTeachId", {
        params: {
          teachId: this.id
        }
      })
      this.chapter = res.data.rows
    },
    //删除章节
    removes(id) {
      this.deleteByPrimaryKey(id)
    },
    async deleteByPrimaryKey(id) {
      const { data: res } = await this.$http.post("/admin/teach/chapter/delete", { chapterId: id })
      this.getPageByTeachId()
    },
    //添加章节
    addTag() {
      if (this.msg.chapterName != '' && this.msg.chapterUrl != '' && this.msg.chapterDuration != '') {
        this.insert()
      }
    },
    async insert() {
      const { data: res } = await this.$http.post("/admin/teach/chapter/add", this.msg)
      this.msg.chapterName = ''
      this.msg.chapterUrl = ''
      this.msg.chapterDuration = ''
      this.getPageByTeachId()
      this.dialogVisible = false
    },
    //编辑章节
    afut(item) {
      this.editorMsg.chapterName = item.chapterName
      this.editorMsg.chapterUrl = item.chapterUrl
      this.editorMsg.chapterDuration = item.chapterDuration
      this.editorMsg.chapterId = item.chapterId
    },
    editorTag() {
      if (this.editorMsg.chapterName != '' && this.editorMsg.chapterUrl != '' && this.editorMsg.chapterDuration != '') {
        this.edits()
      }
    },
    async edits() {
      const { data: res } = await this.$http.post("/admin/teach/chapter/edit", this.editorMsg)
      this.editorMsg.chapterName = ''
      this.editorMsg.chapterUrl = ''
      this.editorMsg.chapterDuration = ''
      this.getPageByTeachId()
      this.dialogVisibleEditor = false
    },
  }
}
</script>





<style scoped lang="less">
.msgList {
  font-size: 14px;
  color: #000;
  list-style-type: none;
  li {
    position: relative;
    padding-left: 100px;
    margin-top: 20px;
    line-height: 20px;
  }
  .title_text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100px;
    text-align: right;
    font-weight: normal;
    color: #a4a4a4;
    padding-right: 10px;
  }
  p {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
}
.title_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
.uploadImg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.min_height_card {
  .box-card {
    margin-top: 20px;
    min-height: 280px;
  }
}
.floatCaid {
  float: right;
  padding: 3px 0;
}
.floatCaids {
  margin-top: -12px;
}
</style>