<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>服务/任务单位</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">服务/任务单位列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <!-- <el-col :span="4">
          <el-input v-model="name" placeholder="搜索标签" @input="change"></el-input>
        </el-col> -->
        <el-col :span="4">
          <el-select v-model="labelValue" placeholder="标签类型" @change="changeLabel">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加单位</el-button>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="unitName" label="单位名称">
        </el-table-column>
        <el-table-column prop="createTime" label="录入时间">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.unitId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
      options: [{
        value: 'labor',
        label: '服务标签'
      }, {
        value: 'task',
        label: '任务标签'
      }],
      labelValue: 'labor'
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取某些类型列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/unit/getPage', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          unitName: this.name,
          unitType: this.labelValue,
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //下拉框选择
    changeLabel(i) {
      this.getList()
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/unit/delete', { unitId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/label_add',
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



</script>
<style scoped lang="scss"></style>